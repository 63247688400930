@import "colors";
@import "custom";

// 1. BS customize
@import "bootstrap-customize";
// 2. BS scss imports
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
// 3. BS utilities override
@import "bootstrap-utilities-border";
// 4. BS all files
@import "~bootstrap/scss/bootstrap";
