$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-navbar-toggler: 992px;

.space-between-company-banner-and-about {
    @media (max-width: $breakpoint-medium) {
        padding-top: 4rem;
    }
    padding-top: 7rem;
}

.space-between-chapters {
    @media (max-width: $breakpoint-medium) {
        padding-top: 5rem;
    }
    padding-top: 9rem;
}

.space-between-chapter-and-footer {
    @media (max-width: $breakpoint-medium) {
        margin-top: 5rem;
    }
    margin-top: 9rem;
}

.z-index-1 {
    z-index: 1;
}

// about.html
.border-radius-top-80 {
    border-top-right-radius: 80px;
    border-top-left-radius: 80px;
}

.border-radius-bottom-80 {
    border-bottom-right-radius: 80px;
    border-bottom-left-radius: 80px;
}

// header.html
.company-logo {
    $company-logo-base-width: 117px;

    -webkit-mask: url(/src/assets/icons/logo.svg) no-repeat center;
    mask: url(/src/assets/icons/logo.svg) no-repeat center;
    -webkit-mask-size: 100%;
    mask-size: 100%;

    &.company-logo--primary {
        background-color: map_get($colors, primary);
        height: 250px;
        width: $company-logo-base-width * 3.5;
        z-index: 1;

        @media (max-width: $breakpoint-medium) {
            width: $company-logo-base-width * 2;
        }
    }

    &.company-logo--white {
        background-color: map_get($colors, base-white);
        height: 40px;
        width: $company-logo-base-width;

        @media (max-width: $breakpoint-medium) {
            width: $company-logo-base-width * 0.7;
        }
    }
}

.navbar-item-letter-spacing {
    letter-spacing: 0.2em;
}

.navbar-language-selector-dropdown {
    @media (max-width: $breakpoint-small) {
        width: 50%;
    }

    @media (min-width: calc($breakpoint-small + 1px)) and (max-width: $breakpoint-navbar-toggler) {
        width: 25%;
    }
}

// company-banner.html
// https://stackoverflow.com/questions/23935758/gradient-over-img-tag-using-css
.banner-bg {
    position: relative;
    display: inline-block;

    &:after {
        background: linear-gradient(360deg, map_get($colors, base-white) 0%, rgba(255, 255, 255, 0) 50%);
        content: "";
        display: inline-block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

// primary-button.html
.btn-primary-small-screen-width-100 {
    @media (max-width: $breakpoint-small) {
        width: 100%;
    }
}

// secondary-button.html
.secondary-button-arrow {
    -webkit-mask: url(/src/assets/icons/arrow-right.svg) no-repeat center;
    mask: url(/src/assets/icons/arrow-right.svg) no-repeat center;
    -webkit-mask-size: 120%;
    mask-size: 120%;
    background-color: map_get($colors, ternary);
    display: inline-block;
    height: 20px;
    width: 20px;
}

.secondary-button:hover {
    opacity: 0.75;
}
