// All bs5 borders are dashed
$utilities: map-merge(
    $utilities,
    (
        "border": (
            property: border,
            values: (
                null: $border-width dashed $border-color,
                0: 0,
            ),
        ),
        "border-top": (
            property: border-top,
            values: (
                null: $border-width dashed $border-color,
                0: 0,
            ),
        ),
        "border-end": (
            property: border-right,
            class: border-end,
            values: (
                null: $border-width dashed $border-color,
                0: 0,
            ),
        ),
        "border-bottom": (
            property: border-bottom,
            values: (
                null: $border-width dashed $border-color,
                0: 0,
            ),
        ),
        "border-start": (
            property: border-left,
            class: border-start,
            values: (
                null: $border-width dashed $border-color,
                0: 0,
            ),
        ),
    )
);

// Border classes extended with media infixes -> https://stackoverflow.com/questions/46412541/applying-borders-on-elements-depending-on-device-size-with-bootstrap
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      .border#{$infix}-top-extended {      border-top: $border-width dashed $border-color !important; }
      .border#{$infix}-end-extended {    border-right: $border-width dashed $border-color !important; }
      .border#{$infix}-bottom-extended {   border-bottom: $border-width dashed $border-color !important; }
      .border#{$infix}-start-extended {     border-left: $border-width dashed $border-color !important; }
  
      .border#{$infix}-top-0-extended {    border-top: 0 !important; }
      .border#{$infix}-end-0-extended {  border-right: 0 !important; }
      .border#{$infix}-bottom-0-extended { border-bottom: 0 !important; }
      .border#{$infix}-start-0-extended {   border-left: 0 !important; }
  
      .border#{$infix}-x-extended {
        border-left: $border-width dashed $border-color !important;
        border-right: $border-width dashed $border-color !important;
      }
  
      .border#{$infix}-y-extended {
        border-top: $border-width dashed $border-color !important;
        border-bottom: $border-width dashed $border-color !important;
      }
    }
  }