$primary: map_get($colors, primary);
$secondary: map_get($colors, secondary);
$success: map_get($colors, ternary);

$accordion-bg: map_get($colors, faq-background);
$accordion-button-bg: tint-color(map_get($colors, faq-background), 100%);
$accordion-button-active-bg: tint-color(map_get($colors, faq-background), 100%);
$accordion-border-color: transparent;

$accordion-icon-width: 2rem;
$accordion-button-icon: url(/src/assets/icons/plus-circle.svg);
$accordion-button-active-icon: url(/src/assets/icons/x-circle.svg);

$border-color: map_get($colors, secondary);

$btn-padding-x-lg: 1.5rem;
$btn-font-weight: 600;

$card-border-width: 0;

$dropdown-min-width: 7rem;
$dropdown-link-color: map_get($colors, secondary);

$position-values: (
    0: 0,
    50: 50%,
    75: 75%,
    100: 100%,
);

$carousel-caption-color: map_get($colors, primary);
$carousel-control-icon-width: 3rem;
$carousel-control-prev-icon-bg: url(/src/assets/icons/left-circle-arrow.svg);
$carousel-control-next-icon-bg: url(/src/assets/icons/right-circle-arrow.svg);

$navbar-nav-link-padding-x: 1rem;

$form-label-color: map_get($colors, primary);
$form-label-font-weight: 500;
$input-color: map_get($colors, secondary);
$form-check-label-color: map_get($colors, primary);